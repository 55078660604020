/*
 * @Author: Paco
 * @Date: 2018-11-22 22:18:40
 * @LastEditTIme: Paco
 * @Description: Main Entry For This Project
 */
import app from '@wtdc/feed';
import models from '@/models';
import Container from '@/routes';

// 初始化组件
import '@/components/antInit';

app
  .useHistory()
  .useRedux(models)  //注入路由
  .useRouter(Container)
  .attach(document.getElementById('App')); //渲染界面

if(module.hot) {
  module.hot.accept();
}

export default app;

/*
 * @Author: weijiali
 * @Date: 2022-06-20 18:53:08
 * @LastEditors: weijiali
 * @LastEditTime: 2022-06-20 19:54:28
 */
import Immutable from 'seamless-immutable';
import vrmodelApi from '@/api/vrmodelApi';

export default {
  namespace: 'vrmodel',
  state: Immutable({
    // airdropSuccessDetail: {},
    // airdropErrorDetail: {},
    nftTypeInfo: {},
    airdropInfo: {}
  }),
  reducers: {
    update: (state, { data, field }) => state.merge({ [field]: data })
  },
  actions: {
    // getAirdropSuccessDetailPage: function(payload) {
    //   return {
    //     type: 'vrmodel/update/getAirdropDetailPage',
    //     field: 'airdropSuccessDetail',
    //     promise: vrmodelApi.getAirdropDetailPage({ ...payload, status: 1 })
    //   };
    // },
    // getAirdropErrorDetailPage: function(payload) {
    //   return {
    //     type: 'vrmodel/update/getAirdropDetailPage',
    //     field: 'airdropErrorDetail',
    //     promise: vrmodelApi.getAirdropDetailPage({ ...payload, status: 0 })
    //   };
    // },
    getNftTypeInfo: function(payload) {
      return {
        type: 'vrmodel/update/getNftTypeInfo',
        field: 'nftTypeInfo',
        promise: vrmodelApi.getNftTypeInfo(payload)
      };
    },
    getAirdropInfo: function(payload) {
      return {
        type: 'vrmodel/update/getAirdropInfo',
        field: 'airdropInfo',
        promise: vrmodelApi.getAirdropInfo(payload)
      };
    }
  }
};


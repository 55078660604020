/**
 *  Date    : 2021/12/09
 *  Author  : weiLin
 *  Declare : 默认菜单
 */
import Utils from '@/utils/utils';

// 图标
export const MENUS_ICON = {
  dc: 'https://file.5rs.me/oss/uploadfe/png/6973bce6a014449ad6735bee2775d2e2.png',
  book: 'https://file.5rs.me/oss/uploadfe/png/e9735e4f4892b042e23a46ed8b067073.png',
  model: 'https://file.5rs.me/oss/uploadfe/png/c06e88ac091f3c7248d13dae6ab35666.png',
  bookOrder: 'https://file.5rs.me/oss/uploadfe/png/7070317010691d73de02c6e5109afc4b.png',
  coupon: 'https://file.5rs.me/oss/uploadfe/png/8bf005a676874945a239099ac9846e68.png',
  activity: 'https://file.5rs.me/oss/uploadfe/png/7a8f959c44d7c0a92490010e6c296eab.png',
  banner: 'https://oss.5rs.me/oss/uploadfe/png/2b88aae0fb31bb9121f5270d1dcc3693.png',
  withdraw: 'https://oss.5rs.me/oss/uploadfe/png/2cfe6d54411185149a310fa515e00732.png',
  user: 'https://oss.5rs.me/oss/uploadfe/png/019c90cff133080bf241cc2b4f879709.png'
};

// 默认菜单
export const PRIVATE_DOMAIN_PERMISSIONS = [
  {
    iconType: MENUS_ICON.dc,
    id: 21,
    invalid: false,
    parentId: 0,
    name: '出版社专区',
    path: '/mrx/agent'
  },
  {
    iconType: MENUS_ICON.dc,
    id: 31,
    invalid: true,
    parentId: 21,
    name: '出版社主页',
    path: '/mrx/agent/list'
  },
  {
    iconType: MENUS_ICON.dc,
    id: 2,
    invalid: false,
    parentId: 0,
    name: '数字藏品',
    path: '/mrx/dc'
  },
  {
    iconType: MENUS_ICON.dc,
    id: 3,
    invalid: true,
    parentId: 2,
    name: '数字藏品',
    path: '/mrx/dc/list'
  },
  {
    iconType: MENUS_ICON.book,
    id: 4,
    invalid: false,
    parentId: 0,
    name: '图书配置',
    path: '/mrx/bookConfig'
  },
  {
    iconType: MENUS_ICON.book,
    id: 5,
    invalid: true,
    parentId: 4,
    name: '图书配置',
    path: '/mrx/bookConfig/list'
  },
  {
    iconType: MENUS_ICON.user,
    id: 6,
    invalid: false,
    parentId: 0,
    name: 'AR图书',
    path: '/mrx/ar'
  },
  {
    iconType: MENUS_ICON.book,
    id: 61,
    invalid: true,
    parentId: 6,
    name: '图书列表',
    path: '/mrx/ar/list'
  },
  {
    iconType: MENUS_ICON.model,
    id: 8,
    invalid: false,
    parentId: 0,
    name: '3D模型库',
    path: '/mrx/model'
  },
  {
    iconType: MENUS_ICON.model,
    id: 9,
    invalid: true,
    parentId: 8,
    name: '3D模型库',
    path: '/mrx/model/list'
  },
  {
    iconType: MENUS_ICON.bookOrder,
    id: 10,
    invalid: false,
    parentId: 0,
    name: '商品订购',
    path: '/mrx/bookOrder'
  },
  {
    iconType: MENUS_ICON.bookOrder,
    id: 11,
    invalid: true,
    parentId: 10,
    name: '商品订购',
    path: '/mrx/bookOrder/list'
  },
  {
    iconType: MENUS_ICON.coupon,
    id: 14,
    invalid: false,
    parentId: 0,
    name: '优惠券',
    path: '/mrx/coupon'
  },
  {
    iconType: MENUS_ICON.coupon,
    id: 15,
    invalid: true,
    parentId: 14,
    name: '优惠券',
    path: '/mrx/coupon/list'
  },
  {
    iconType: MENUS_ICON.activity,
    id: 22,
    invalid: false,
    parentId: 0,
    name: '拉新活动',
    path: '/mrx/activity'
  },
  {
    iconType: MENUS_ICON.activity,
    id: 23,
    invalid: true,
    parentId: 22,
    name: '拉新活动',
    path: '/mrx/activity/list'
  },
  {
    iconType: MENUS_ICON.banner,
    id: 26,
    invalid: false,
    parentId: 0,
    name: '平台',
    path: '/mrx/channel'
  },
  {
    iconType: MENUS_ICON.banner,
    id: 27,
    invalid: true,
    parentId: 26,
    name: 'Banner',
    path: '/mrx/channel/bannerList'
  },
  {
    iconType: MENUS_ICON.withdraw,
    id: 30,
    invalid: false,
    parentId: 0,
    name: '寄售',
    path: '/mrx/withdrawOrder'
  },
  {
    iconType: MENUS_ICON.withdraw,
    id: 31,
    invalid: true,
    parentId: 30,
    name: '提现审核',
    path: '/mrx/withdrawOrder/list'
  },
  {
    iconType: MENUS_ICON.user,
    id: 33,
    invalid: false,
    parentId: 0,
    name: '用户',
    path: '/mrx/buyBack'
  },
  {
    iconType: MENUS_ICON.user,
    id: 34,
    invalid: true,
    parentId: 33,
    name: '回购情况',
    path: '/mrx/buyBack/list'
  }
];

// 格式化配置路由
export default function setPermission(userInfo) {
  let [...routerPath] = PRIVATE_DOMAIN_PERMISSIONS;
  // 账号权限控制
  if(['vr'].includes(userInfo.userName)) {
    routerPath = routerPath.filter(v => v.name === '3D模型库');
  }
  return Utils.unFlatMap(routerPath);
}

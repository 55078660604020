import React from 'react';
import { Switch } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import zhCN from 'antd/lib/locale/zh_CN';

import './App.scss';

export default class App extends React.PureComponent {

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    return (
      <ConfigProvider locale={zhCN}>
        <Switch>
          {this.props.children}
        </Switch>
      </ConfigProvider>

    );
  }
}
